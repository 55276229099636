import React from 'react';
import { isFunction } from 'lodash';

import Layout from '../components/Layout';
import Seo from '../components/Seo';
import isSubpage from '../utils/isSubpage';
import Subpage from '../components/Subpage';
import styles from './faq.module.scss';

const DATA = [
  {
    answer: () => (
      <>
        Please follow this link to get more info&nbsp;
        <a
          href="https://help.figma.com/hc/en-us/articles/360041003114-Import-files-into-Figma"
          target="_blank"
          rel="noopener noreferrer">
          https://help.figma.com/hc/en-us/articles/360041003114-Import-files-into-Figma
        </a>
      </>
    ),
    question: 'How to import the file into Figma?',
  },
  {
    answer: () => (
      <>
        All payments are processed by Gumroad and in order to receive an invoice
        for your purchase, please visit&nbsp;
        <a
          href="https://customers.gumroad.com/article/194-i-need-an-invoice"
          target="_blank"
          rel="noopener noreferrer">
          https://customers.gumroad.com/article/194-i-need-an-invoice
        </a>
      </>
    ),
    question: 'I need an invoice, where I can request it?',
  },
  {
    answer:
      'Yes, you can use it in unlimited projects by purchasing only one license. This is not a license per use and you can reuse illustrations over and over again.',
    question:
      'Can I purchase this product once and use it in more than one project?',
  },
  {
    answer: 'No, one license is exclusively for one person.',
    question: 'Can multiple users share a single license?',
  },
  {
    answer: () => (
      <>
        Yes, it is possible. In order to do this please contact me at&nbsp;
        <a
          href="mailto:hello@gregdlubacz.com"
          target="_blank"
          rel="noopener noreferrer">
          hello@gregdlubacz.com
        </a>
      </>
    ),
    question:
      'I want to change the email address in my Licence. Is it possible?',
  },
  {
    answer: () => (
      <>
        Yes, all updates are free and in order to get the newest version of the
        purchased product please follow these instructions&nbsp;
        <a
          href="https://customers.gumroad.com/article/199-how-do-i-access-my-purchase"
          target="_blank"
          rel="noopener noreferrer">
          https://customers.gumroad.com/article/199-how-do-i-access-my-purchase
        </a>
        . &nbsp;Even if we add more illustrations into the purchased package you
        will get free update. Every time we release an update we will be sending
        a newsletter notifying that an update is available and ready to
        download.
      </>
    ),
    question: 'Do you offer free updates of the product?',
  },
  {
    answer:
      'Yes, we provide a 30% discount for students. In order to receive a discount you will be asked for a proof that you are actually a student.',
    question: 'I’m a student, do you provide discounts?',
  },
  {
    answer: () => (
      <>
        Yes, however you cannot provide source files as a part of your own
        product. You cannot sell or redistribute Whoooa! illustrations as your
        own product, share source files with other people. It’s perfectly fine
        to use illustrations in non-editable formats for presentations of your
        own products.
        <br />
        Please note: Only a full version of Whoooa! can be used in commercial
        products/projects. A free version of illustrations may be used for
        personal purposes only.
      </>
    ),
    question:
      'I’m creating commercial templates. Can I use illustrations in templates that will be sold on various marketplaces?',
  },
  {
    answer:
      'Yes, however this is a digital product and once illustrations are downloaded they become non-refundable.',
    question: 'Do you offer refunds?',
  },
  {
    answer:
      'Yes, you can use any colour you can only imagine. There are no limitations. However, please bear in mind that for the best result, colors should be a mix of dark and light shades.',
    question: 'Can I use a color of my choice when editing illustrations?',
  },
  {
    answer: () => (
      <>
        Please follow these instructions. -&nbsp;
        <a
          href="https://gregdlubacz.wistia.com/medias/tqc5b2w2tu"
          target="_blank"
          rel="noopener noreferrer">
          https://gregdlubacz.wistia.com/medias/tqc5b2w2tu
        </a>
      </>
    ),
    question: 'How to edit colors in Sketch?',
  },
  {
    answer: () => (
      <>
        Please follow these instructions. -&nbsp;
        <a
          href="https://gregdlubacz.wistia.com/medias/q0e3t1unfr"
          target="_blank"
          rel="noopener noreferrer">
          https://gregdlubacz.wistia.com/medias/q0e3t1unfr
        </a>
      </>
    ),
    question: 'How to edit colors in Figma?',
  },
  {
    answer: () => (
      <>
        Please follow these instructions. -&nbsp;
        <a
          href="https://gregdlubacz.wistia.com/medias/kdl3dp7sn3"
          target="_blank"
          rel="noopener noreferrer">
          https://gregdlubacz.wistia.com/medias/kdl3dp7sn3
        </a>
      </>
    ),
    question: 'How to edit colors in Adobe XD?',
  },
  {
    answer: () => (
      <>
        Yes, please contact us for a quote at:&nbsp;
        <a href="mailto:hello@gregdlubacz.com">hello@gregdlubacz.com</a>{' '}
        or&nbsp;
        <a href="mailto:olamac.art@gmail.com">olamac.art@gmail.com</a> as
        nothing beats a bespoke illustration.
      </>
    ),
    question:
      'I need custom illustrations created. Can you produce them for me?',
  },
];

const FAQPage = ({ location }) => (
  <Layout isSubpage={isSubpage(location.pathname)}>
    <Seo title="FAQ - Whoooa! Illustrations" />
    <Subpage className={styles.faq} title="FAQ">
      {DATA.map(({ answer: Answer, question }) => (
        <div className={styles.faq__question} key={question}>
          <h3 className={styles.faq__title}>Q: {question}</h3>
          <p className={styles.faq__content}>
            {isFunction(Answer) ? <Answer /> : Answer}
          </p>
        </div>
      ))}
      <p className={styles.faq__info}>
        Still need help? Please feel free to contact me if you need any further
        information&nbsp;
        <a href="mailto:hello@gregdlubacz.com">hello@gregdlubacz.com</a>
      </p>
    </Subpage>
  </Layout>
);

export default FAQPage;
